import DashboardWrapper from '../../../components/BestSchoolsAwards/DashboardWrapper/DashboardWrapperTide'
import * as DashboardStyles from './Dashboard.module.scss'
import React, { useState, useEffect, useContext } from 'react'
import { LoginContext } from '../../../lib/utils'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'

const TideLogout = () => {
  const { usr, setUsr } = useContext(LoginContext)
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  const [initialised, setInitialised] = useState<boolean>(false)

  useEffect(() => {
    if (usr?.viewer?.email) {
      setLoggedIn(true)
      setInitialised(true)
    }
  }, [usr])

  const logOut = () => {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('token')
      localStorage.removeItem('musr')
      setLoggedIn(false)
      setUsr &&
        setUsr({
          viewer: null
        })
    }
  }

  useEffect(() => {
    if (initialised && !loggedIn) {
      navigate('/women-in-business/dashboard/login/')
    }
  }, [loggedIn])

  return (
    <div>
      <Helmet>
        <title>She Means Business | Muddy Stilettos</title>
      </Helmet>
      <DashboardWrapper>
        <div className={DashboardStyles.Intro}>
          {loggedIn && (
            <div>
              <p>
                You&apos;re logged in as <span>{usr?.viewer?.name ?? ''}</span>
              </p>
              <div className={DashboardStyles.Button} onClick={logOut}>
                Log Out
              </div>
            </div>
          )}
        </div>
      </DashboardWrapper>
    </div>
  )
}

export default TideLogout
